import * as storage from './storage';
import Settings from '../domain/Settings';

export type GigyaModes = 'premium' | 'freemium' | 'dpg_mm' | 'nunl';

export function getGigyaId(type = Settings.getInstance().gigya?.type): string | false {
	switch (type) {
		case 'dpg_mm': {
			return _sanomaModule();
		}

		case 'nunl': {
			return _getNuNlGigyaId();
		}

		case 'freemium': {
			return _freemium();
		}

		case 'premium': {
			return _premium();
		}

		default: {
			return false;
		}
	}
}

function _sanomaModule() {
	const gigyaid = storage.getFromCookie('SA_UID');

	if (gigyaid) {
		return decodeURIComponent(gigyaid);
	}

	return false;
}

function _getNuNlGigyaId(): false {
	const gigyaId = storage.get('NU.user_uid_store');

	return gigyaId ? JSON.parse(gigyaId).data : false;
}

function _freemium() {
	// @ts-ignore
	const dl = window.dataLayer?.find(l => l.user?.account_id);

	if (dl) {
		// @ts-ignore
		return dl.user.account_id;
	}

	return false;
}

function _premium() {
	const dl = window.dataLayer?.find(l => l['gigya-id']);

	if (dl) {
		return dl['gigya-id'] as string | false | null;
	}

	return false;
}
